import React from "react";
import { navigate } from "gatsby-plugin-intl";
import { Helmet } from "react-helmet";
import { isIE } from "react-device-detect";

export default class UnsupportedBrowser extends React.Component {
  componentDidMount() {
    if (!isIE) navigate("/");
  }

  render() {
    return (
      <>
        <Helmet encodeSpecialCharacters={false}>
          <title>You are using an outdated browser</title>
        </Helmet>
        <div style={{ position: "absolute", height: "100%", width: "100%", backgroundColor: "#ECF0EF" }}>
          <div
            style={{
              textAlign: "center",
              padding: "30px",
              color: "#3E5D58",
              position: "absolute",
              width: "100%",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <h1 style={{ fontSize: "22px", marginBottom: "30px" }}>
              Unfortunately, you are using an <b>outdated</b> browser.
            </h1>
            <p style={{ marginBottom: "20px" }}>
              Please <a href="#">upgrade your browser</a> to improve your experience and security.
            </p>
            <p style={{ marginBottom: "5px" }}>Supported browsers:</p>
            <p>
              <a href="https://www.google.com/chrome/" rel="noopener noreferrer" className="underline hover:opacity-70">
                Google Chrome
              </a>
            </p>
            <p>
              <a
                href="https://www.mozilla.org/en-US/firefox/new/"
                rel="noopener noreferrer"
                className="underline hover:opacity-70"
              >
                Mozilla Firefox
              </a>
            </p>
            <p>
              <a
                href="https://www.microsoft.com/en-us/edge"
                rel="noopener noreferrer"
                className="underline hover:opacity-70"
              >
                Microsoft Edge
              </a>
            </p>
            <p>
              {" "}
              <a
                href="https://support.apple.com/en-nz/safari"
                rel="noopener noreferrer"
                className="underline hover:opacity-70"
              >
                Safari
              </a>
            </p>

            <img src="/images/plant-and-food-research-logo.svg" style={{ margin: "80px auto 0 auto", width: "150px" }} />
          </div>
        </div>
      </>
    );
  }
}
